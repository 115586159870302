<template>
  <table>
    <thead>
      <tr>
      <th align="center">#</th>
      <th align="left">URIパス</th>
      <th align="left">分類</th>
      <th align="center">ログイン</th>
      <th align="center">開発分類</th>
      <th align="left">ページ名</th>
      <th align="center">分担担当</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="rec in pageList" :key="rec[0]">
        <tr @click="nextPage(rec[1])">
          <td align="center">{{rec[0]}}</td>
          <td align="left">{{rec[1]}}</td>
          <td align="left">{{rec[2]}}</td>
          <td align="center">{{rec[3]}}</td>
          <td align="center">{{rec[4]}}</td>
          <td align="left">{{rec[5]}}</td>
          <td align="center">{{rec[6]}}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'PageList',
  data() {
    return {
      pageList: [
        ["1","/index","ホームページ","不要","固定","ホーム","松川"],
        ["2","/tailor_made","ホームページ","不要","固定","オーダーメイド","松川"],
        ["3","/maintenance","ホームページ","不要","固定","定期点検","松川"],
        ["4","/company","ホームページ","不要","固定","会社案内","門野"],
        ["5","/access","ホームページ","不要","固定","<アクセス案内>", "門野"],
        ["6","/6","ホームページ","不要","固定","<ランディングページ>"],
        ["7","/inquiry","ホームページ","不要","固定*","お問い合わせ", "門野"],
        ["8","/application","ホームページ","不要","アプリ","予約・申し込み", "門野"],
        ["9","/column","ホームページ","不要","アプリ","コラム","松川"],
        ["10","/faq","ホームページ","不要","アプリ","よくある質問", "門野"],
        ["11","/item_a","商品","不要","固定","掛け布団（一般向け）", "松川"],
        ["12","/item_b","商品","不要","固定","敷布団（一般向け）", "松川"],
        ["13","/item_c","商品","不要","固定","枕（一般向け）", "松川"],
        ["14","/items","商品","要","アプリ","商品リスト（檀家さん向け）", "門野"],
        ["15","/item_detail","商品","要/不要","アプリ","商品詳細ページ"],
        ["19","/cart","オンライン購入","不要","アプリ","カート内容", "門野"],
        ["20","/order_customer","オンライン購入","不要","アプリ","顧客情報入力", "門野"],
        ["21","/order_confirm","オンライン購入","不要","アプリ","確認画面", "門野"],
        ["22","/22","オンライン購入","不要","アプリ","決済"],
        ["23","/mypage","マイページ","要","アプリ","メイン(お気に入り、購入履歴)","松川"],
        ["24","/maintenance_list","マイページ","要","アプリ","<点検リスト>"],
        ["25","/check_result","マイページ","要","アプリ","オーダーメイド測定結果","松川"],
        ["26","/login","共通","不要","アプリ","ログイン", "門野"],
        ["27","/regist","共通","不要","アプリ","会員登録", "門野"],
        ["28","/dregist","共通","不要","アプリ","会員登録（檀家さん向け）", "門野"],
        ["29","/column_regist","管理","要","アプリ","<コラム登録>"],
        ["30","/column_confirm","管理","要","アプリ","<コラム承認>"],
        ["31","/order_search","管理","要","アプリ","<注文照会>"],
        ["32","/order_download","管理","要","アプリ","<注文データダウンロード>"],
        ["35","/maintenance_search","管理","要","アプリ","<点検照会>"],
        ["36","/maintenance_download","管理","要","アプリ","<点検データダウンロード>"],
        ["37","/customer_search","管理","要","アプリ","<顧客照会>"],
        ["38","/customer_download","管理","要","アプリ","<顧客データダウンロード>"],
        ["41","/item_maintenance","管理","要","アプリ","<商品（檀家さん向け）メンテナンス>"]
      ]
    }
  },
  methods: {
    nextPage(page) {
      this.$router.push(page);
    }
  }
}
</script>

<style>
body {
  margin: 0px;
}
</style>

<style scoped>
body {
  margin: 0px;
}
table {
  border-collapse: separate;
  border-spacing:0;
}
th,td {
  border: solid 1px;
}
thead th {
  background-color: lightblue;
  position: -webkit-sticky;
  position: sticky;
  border-spacing:0;
  top: 0;
  z-index: 1;
}
table tr:hover td {
  background-color: lightpink;
}
</style>
